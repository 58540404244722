<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">PRODUCTOS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>REPORTE GENERAL DE PRODUCTOS</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span><strong>MARCA: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre"
            v-model="laboratorioSelected"
            :suggestions="laboratoriosListadoFiltrado"
            @complete="buscarLaboratorio($event)"
            placeholder="Buscar Marca"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.nombre }}
                <!-- **
                  {{ slotProps.item.nombre_proveedor }} -->
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-3"  v-show="false">
          <span><strong>PROVEEDOR: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre_completo"
            v-model="proveedorSelected"
            :suggestions="proveedoresFiltrado"
            :filter="true"
            @complete="buscarProveedor($event)"
            placeholder="Buscar Proveedor"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>CASO DE USO / APLICACIÓN:</strong></span>
          <div class="p-inputgroup">
            <InputText v-model="caso_uso" />
          </div>
        </div>
        <div class="field col-12 md:col-5">
          <span><strong>NOMBRE/DESCRIPCI&Oacute;N:</strong></span>
          <div class="p-inputgroup">
            <InputText v-model="descripcion" @keyup.enter="BuscarFiltro()" />
            <Button
              icon="pi pi-search"
              class="p-button-warning"
              @click="BuscarFiltro()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6" v-show="false">
          <span><strong>NOMBRE PRODUCTO:</strong></span>
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Escriba Nombre Producto"
          >
            <template #item="slotProps">
              <div>
                <strong>{{ slotProps.item.descripcion }}</strong
                ><span><strong> --- </strong></span>
                {{ slotProps.item.fabricas.nombre_proveedor
                }}<span><strong> ---</strong></span>
                {{ slotProps.item.fabrica_nombre }}
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>UNIDAD DE MEDIDA: </strong> </span>
          <Dropdown
            v-model="unidadmedidaSeleccionado"
            :options="unidadmedidas"
            :filter="true"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>PRECIO INICIAL: </strong></span>
          <InputNumber v-model="precio_minimo" :showButtons="true" :min="0" />
        </div>
        <div class="field col-12 md:col-3" v-show="false">
          <span><strong>PRECIO FINAL: </strong></span>
          <InputNumber v-model="precio_maximo" :showButtons="true" :min="0" />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="BUSCAR"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Buscar Productos'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="reporte_ventas"
        key="id"
        :value="productos"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Productos..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2" v-if="false">
              <Button
                v-if="'R_Productos DescargarPDF' in auth.user.permissions"
                label="Imprimir PDF"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger mr-2"
                v-tooltip.top="'Imprimir Reporte PDF'"
                :loading="generandoPDF"
                :disabled="generandoPDF"
                @click="generarPDF"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2" v-if="false">
              <Button
                v-if="'R_Productos DescargarEXCEL' in auth.user.permissions"
                label="Exportar Excel"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success mr-1"
                v-tooltip.top="'Imprimir Reporte Excel'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportProductos"
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="codigo_interno" header="CÓD_INT" :sortable="true">
        </Column>
        <Column field="descripcion" header="DESCRIPCIÓN/PRODUCTO">
          <template #body="{ data }">
            <div class="flex flex-column">
              <span class="text-sm font-bold">{{ data.descripcion }}</span>
            </div>
          </template>
        </Column>

        <Column field="caso_uso" header="APLICACION">
          <template #body="{ data }">
            <div class="flex flex-column">
              <span class="text-sm font-bold">{{ data.caso_uso }}</span>
            </div>
          </template>
        </Column>
        <Column field="cantidad" header="STOCK">
          {{ data.cantidad }}
        </Column>
        <Column field="nombre_unidadmedida" header="UNIDAD">
          {{ data.nombre_unidadmedida }}
        </Column>
        <Column field="precio_sugerido" header="PRECIO VENTA">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import LaboratorioService from "@/service/LaboratorioService";
import ProveedorService from "@/service/ProveedorService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      enviando: false,
      generandoPDF: false,
      exportando: false,
      unidadmedidas: [{ id: 0, nombre: "TODAS" }],
      unidadmedidaSeleccionado: 0,
      precio_minimo: 0,
      laboratorioSelected: null,
      laboratoriosListadoFiltrado: [],
      precio_maximo: 0,
      productos: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      proveedorSelected: null,
      proveedoresFiltrado: [],
      productoSelected: null,
      productosListadoFiltrado: [],
      caso_uso: "",
      descripcion: "",
    };
  },
  productoService: null,
  unidadmedidaService: null,
  laboratorioService: null,
  proveedorService: null,
  auth: null,
  created() {
    this.productoService = new ProductService();
    this.unidadmedidaService = new UnidadmedidaService();
    this.laboratorioService = new LaboratorioService();
    this.proveedorService = new ProveedorService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarUnidadmedidas();
  },
  methods: {
    buscarProveedor(event) {
      setTimeout(() => {
        this.proveedorService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.proveedoresFiltrado = [...response.proveedores];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productoService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    exportProductos() {
      if (this.productos.length > 0) {
        let datos = {
          unidadmedida: this.unidadmedidaSeleccionado,
          fabrica: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          producto: this.productoSelected ? this.productoSelected.id : null,
        };
        this.productoService.exportarProductos(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación Exitosa",
            detail: "Se ha exportado correctamente los productos",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.productos.length > 0) {
        this.generandoPDF = true;
        let datos = {
          fabrica: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
          unidadmedida: this.unidadmedidaSeleccionado,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          producto: this.productoSelected ? this.productoSelected.id : null,
        };
        this.productoService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.productos = [];
      this.enviando = true;
      this.cargarReporteProductos();
    },
    cargarReporteProductos() {
      let datos = {
        unidadmedida: this.unidadmedidaSeleccionado,
        fabrica: this.laboratorioSelected ? this.laboratorioSelected.id : null,
        precio_minimo: this.precio_minimo,
        precio_maximo: this.precio_maximo,
        proveedor_id: this.proveedorSelected ? this.proveedorSelected.id : null,
        producto: this.productoSelected ? this.productoSelected.id : null,
        caso_uso: this.caso_uso ? this.caso_uso : null,
        descripcion: this.descripcion ? this.descripcion : null,
      };
      this.productoService
        .filtrarProductos(datos)
        .then((response) => {
          this.productos = response.productos;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE").format(numero);
    },
    cargarUnidadmedidas() {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((data) => {
          data.forEach((unidadmedida) => {
            this.unidadmedidas.push(unidadmedida);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buscarLaboratorio(event) {
      setTimeout(() => {
        this.laboratorioService
          .buscarLaboratorioXNombre({ texto: event.query })
          .then((response) => {
            this.laboratoriosListadoFiltrado = [...response.laboratorios];
          });
      }, 250);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
.p-invalid {
  color: red;
}
</style>
